import React, { PureComponent } from 'react';
import Downshift from 'downshift';
import styles from './Dropdown.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export default class Dropdown extends PureComponent {
  render() {
    const {
      items,
      label,
      listHeight = null,
      onChange,
      className,
      downshiftRef,
      ...rest
    } = this.props;
    return (
      <Downshift
        ref={downshiftRef || null}
        itemToString={item => (item ? item.name : '')}
        getValue={item => item.value}
        onChange={onChange}
        {...rest}
      >
        {({
          getLabelProps,
          getItemProps,
          isOpen,
          toggleMenu,
          selectedItem,
          highlightedIndex
        }) => (
          <div
            className={`${className} ${cx({
              combobox: true
            })}`}
          >
            {label && (
              <label
                {...getLabelProps({
                  htmlFor: 'my-select'
                })}
              >
                {label}
              </label>
            )}
            <div className={styles.fieldset}>
              <button
                type="button"
                className={cx({ button: true, mainButton: true })}
                data-toggle="dropdown"
                aria-haspopup="true"
                onClick={toggleMenu}
                aria-expanded={isOpen}
              >
                <span>
                  {selectedItem
                    ? selectedItem.name
                    : items && items.length
                      ? items[0].name
                      : null}
                </span>
                <i className={cx({ icon: true, up: isOpen, down: !isOpen })} />
              </button>
              {isOpen ? (
                <div
                  className={cx({
                    menu: true,
                    scrolling: listHeight && listHeight > 0
                  })}
                  style={{ height: listHeight || null }}
                >
                  {items.map((item, idx) => {
                    return (
                      <button
                        {...getItemProps({ item })}
                        key={item.value}
                        className={cx({
                          button: true,
                          item: true,
                          current: highlightedIndex === idx,
                          active: selectedItem === item
                        })}
                        style={{
                          cursor: selectedItem === item ? 'default' : 'pointer'
                        }}
                      >
                        <span>{item.name}</span>
                      </button>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        )}
      </Downshift>
    );
  }
}
