import React, { PureComponent } from 'react';
import Columns from 'react-bulma-components/lib/components/columns';
import Dropdown from 'components/Dropdown';
import styles from './WritersBooksFilter.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

const ddShowingConfig = [
  { name: 'Authors', value: 'authors' },
  { name: 'Books', value: 'books' },
  { name: 'Awards', value: 'awards' },
  { name: 'Programs', value: 'programs' }
];

const getDdSortByConfig = (awards, programs) => {
  return {
    authors: [
      { name: 'Recent', value: 'recent' },
      { name: 'A-Z', value: 'a-z' }
    ],
    books: [
      { name: 'Recent', value: 'recent' },
      { name: 'Title', value: 'title' },
      { name: 'Author', value: 'author' }
    ],
    awards: [{ name: 'All', value: 'all' }].concat(
      awards.map(a => ({ name: a.data.title.text, value: a.uid }))
    ),
    programs: [{ name: 'All', value: 'all' }].concat(
      programs.map(a => ({ name: a.data.title.text, value: a.uid }))
    )
  };
};

const ddTypeConfig = [
  { name: 'Finalists', value: 'finalists' },
  { name: 'Jurors', value: 'jurors' }
];
// const ddYearConfig = getYears(2008);

class WritersBooksFilter extends PureComponent {
  state = { ddShowingItems: ddShowingConfig };

  static getDerivedStateFromProps(props) {
    let newState = null;

    if (props.showing) {
      newState = {};
      newState.ddSortByItems = getDdSortByConfig(
        props.awards || [],
        props.programs || []
      )[props.showing];
    }

    if (props.years !== null) {
      if (!newState) {
        newState = {};
      }
      newState.ddYearItems = props.years;
    }

    if (props.showing === 'awards') {
      newState.ddTypeItems = ddTypeConfig;
    }

    return newState;
  }

  parentChange = (showing, sort, year, type) => {
    this.props.onChange({
      showing,
      sort,
      year,
      type
    });
  };

  onShowingChange = changes => {
    this.parentChange(changes.value);
  };

  onSortByChange = changes => {
    this.parentChange(
      this.props.showing,
      changes.value,
      this.props.year,
      this.props.type
    );
  };

  onSearchChange = changes => {
    // console.log('searchChange', changes);
    const { inputValue } = changes;
    if (this.state.searchValue !== inputValue) {
      this.setState({ searchValue: inputValue }, this.parentChange);
    }
  };

  onYearChange = changes => {
    this.parentChange(
      this.props.showing,
      this.props.sort,
      changes.value,
      this.props.type
    );
  };

  onTypeChange = changes => {
    this.parentChange(
      this.props.showing,
      this.props.sort,
      this.props.year,
      changes.value
    );
  };

  setSortRef = r => {
    this.sortByDd = r;
  };

  setYearDdRef = r => {
    this.yearDd = r;
  };

  setTypeDdRef = r => {
    this.typeDd = r;
  };

  render() {
    return (
      <Columns mobile multiline className={cx({ filter: true })}>
        <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
          <Columns mobile multiline>
            <Columns.Column mobile={{ size: 11 }} tablet={{ size: 2 }}>
              <Dropdown
                selectedItem={
                  this.state.ddShowingItems.filter(
                    item => item.value === this.props.showing
                  )[0]
                }
                items={this.state.ddShowingItems}
                onChange={this.onShowingChange}
                className={styles.showingFilter}
              />
            </Columns.Column>
            {this.props.showing === 'authors' && (
              <Columns.Column mobile={{ size: 11 }} tablet={{ size: 3 }}>
                <Dropdown
                  selectedItem={
                    this.state.ddSortByItems.filter(
                      item => item.value === this.props.sort
                    )[0]
                  }
                  items={this.state.ddSortByItems}
                  onChange={this.onSortByChange}
                  className={cx({
                    sortbyFilter: true,
                    [this.state.showingValue]: true
                  })}
                />
              </Columns.Column>
            )}
            {this.props.showing === 'books' && (
              <Columns.Column mobile={{ size: 11 }} tablet={{ size: 3 }}>
                <Dropdown
                  selectedItem={
                    this.state.ddSortByItems.filter(
                      item => item.value === this.props.sort
                    )[0]
                  }
                  items={this.state.ddSortByItems}
                  onChange={this.onSortByChange}
                  className={cx({
                    sortbyFilter: true,
                    [this.state.showingValue]: true
                  })}
                />
              </Columns.Column>
            )}
            {this.props.showing === 'awards' && (
              <React.Fragment>
                <Columns.Column mobile={{ size: 11 }} tablet={{ size: 5 }}>
                  <Dropdown
                    selectedItem={
                      this.state.ddSortByItems.filter(
                        item => item.value === this.props.sort
                      )[0]
                    }
                    items={this.state.ddSortByItems}
                    onChange={this.onSortByChange}
                    className={cx({
                      sortbyFilter: true,
                      [this.state.showingValue]: true
                    })}
                  />
                </Columns.Column>
                {this.state.ddYearItems && this.state.ddYearItems.length && (
                  <Columns.Column mobile={{ size: 11 }} tablet={{ size: 2 }}>
                    <Dropdown
                      downshiftRef={this.setYearDdRef}
                      selectedItem={
                        this.state.ddYearItems.filter(
                          item => item.value === parseInt(this.props.year)
                        )[0]
                      }
                      items={this.state.ddYearItems}
                      onChange={this.onYearChange}
                      listHeight={276}
                    />
                  </Columns.Column>
                )}
                {this.props.sort !== 'matt-cohen-award' &&
                  this.state.ddTypeItems &&
                  this.state.ddTypeItems.length && (
                    <Columns.Column mobile={{ size: 11 }} tablet={{ size: 2 }}>
                      <Dropdown
                        downshiftRef={this.setTypeDdRef}
                        selectedItem={
                          this.state.ddTypeItems.filter(
                            item => item.value === this.props.type
                          )[0]
                        }
                        items={this.state.ddTypeItems}
                        onChange={this.onTypeChange}
                        listHeight={276}
                      />
                    </Columns.Column>
                  )}
              </React.Fragment>
            )}
            {this.props.showing === 'programs' && (
              <React.Fragment>
                <Columns.Column mobile={{ size: 11 }} tablet={{ size: 5 }}>
                  <Dropdown
                    selectedItem={
                      this.state.ddSortByItems.filter(
                        item => item.value === this.props.sort
                      )[0]
                    }
                    items={this.state.ddSortByItems}
                    onChange={this.onSortByChange}
                    className={cx({
                      sortbyFilter: true,
                      [this.state.showingValue]: true
                    })}
                  />
                </Columns.Column>
                {this.state.ddYearItems && this.state.ddYearItems.length && (
                  <Columns.Column mobile={{ size: 11 }} tablet={{ size: 2 }}>
                    <Dropdown
                      downshiftRef={this.setYearDdRef}
                      selectedItem={
                        this.state.ddYearItems.filter(
                          item => item.value === parseInt(this.props.year)
                        )[0]
                      }
                      items={this.state.ddYearItems}
                      onChange={this.onYearChange}
                      listHeight={276}
                    />
                  </Columns.Column>
                )}
              </React.Fragment>
            )}
          </Columns>
        </Columns.Column>
        {/* <Columns.Column mobile={{ size: 12 }} tablet={{ size: 4 }}>
          <Downshift onStateChange={this.onSearchChange}>
            {({ getInputProps }) => (
              <div className={styles.search}>
                <Input
                  inputProps={getInputProps({
                    placeholder: "Search"
                  })}
                />
                <i className="icon">
                  <FontAwesomeIcon icon={faSearch} />
                </i>
              </div>
            )}
          </Downshift>
        </Columns.Column> */}
      </Columns>
    );
  }
}

export default WritersBooksFilter;
