import React, { PureComponent } from "react";
import {
  awardsOrder,
  programsOrder,
} from "../../templates/WritersBooks/sortOrders";

import BreakpointListener from "components/BreakpointListener";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LazyLoad from "react-lazyload";
import { Link } from "gatsby";
import Section from "react-bulma-components/lib/components/section";
import classNames from "classnames/bind";
import events from "utils/events";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { firstBy } from "thenby";
import getAuthorNames from "utils/getAuthorNames";
import { renderFluidImage } from "utils/imageHelpers";
import { renderText } from "utils/renderHelpers";
import styles from "./WritersBooksGrid.module.scss";

const cx = classNames.bind(styles);

const WriterRenderer = ({ ...props }) => {
  const { data, slug } = props;
  const { first_name, last_name, image, cover_image } = data;
  let carousel = image
    ? image.carousel
    : cover_image
    ? cover_image.carousel
    : null;

  return (
    <Columns.Column
      mobile={{ size: 6 }}
      tablet={{ size: 3 }}
      desktop={{ size: 3 }}
      widescreen={{ size: 2 }}
    >
      <Link
        to={`/${slug}/`}
        title={`${first_name.text} ${last_name.text}`}
        className={cx({
          card: true,
        })}
      >
        <figure>{renderFluidImage(carousel, null, {}, "writer")}</figure>
        <h2
          className={styles.name}
        >{`${first_name.text} ${last_name.text}`}</h2>
        <div className={styles.moreLink}>
          <span>See bio</span>
          <i className="icon">
            <FontAwesomeIcon icon={faPlus} />
          </i>
        </div>
      </Link>
    </Columns.Column>
  );
};

const BookRenderer = ({ ...props }) => {
  const { data } = props;
  let { title, cover_image, author, authors } = data;
  const { carousel } = cover_image;
  let authorSlug;
  if (!author || !author.document) {
    return null;
  }
  if (author && author.document) {
    author = author.document[0];
    authorSlug = author.slug;
  }
  //   const {first_name, last_name} = author.data

  return (
    <Columns.Column
      mobile={{ size: 6 }}
      tablet={{ size: 3 }}
      widescreen={{ size: 2 }}
    >
      <Link
        to={`/${authorSlug}/`}
        title={`${title.text}`}
        className={cx({
          card: true,
        })}
      >
        <figure>{renderFluidImage(carousel, null, {}, "book")}</figure>

        {renderText(
          title,
          `h2`,
          styles.name,
          {},
          null,
          null,
          null,
          false,
          null,
          true,
          data.short_story === "true"
        )}
        <h3
          className={styles.moreLink}
          dangerouslySetInnerHTML={{
            __html: getAuthorNames(
              authors
                .map((a) =>
                  a.author1
                    ? { author: a.author1.document[0], role: a.type }
                    : false
                )
                .filter(Boolean)
            ),
          }}
        />
      </Link>
    </Columns.Column>
  );
};

const AwardRenderer = ({
  book,
  winner,
  writer,
  showFinalistTag,
  showWinnerTag,
  isWinner = false,
  hasFinalists,
  awardId,
  year,
  type,
  debug = false,
  customLabel = false,
  showWinner = true,
  ...rest
}) => {
  // // if (debug) {
  // console.log({
  //   writer,
  //   year,
  //   type,
  //   hasFinalists,
  //   type,
  //   hasFinalists,
  //   awardId,
  // });
  // // }
  if (!writer) {
    return null;
  }
  const { data, slug } = writer;
  // if (book) {

  let hasBook = book && book.data;
  let carousel = hasBook
    ? book.data.cover_image.carousel
    : writer.data.image.carousel;

  let alwaysShowAuthor =
    awardId === `rbc-bronwen-wallace-award-for-emerging-writers` ||
    awardId === `writers-trust-mcclelland-stewart-journey-prize`;

  let title = hasBook
    ? book.data.title
    : { text: `${writer.data.first_name.text} ${writer.data.last_name.text}` };

  let finalistTag = "Finalists";
  let authorids = [];
  let programData = null;
  switch (true) {
    case type === "jurors":
      finalistTag = "Jurors";
      break;
    case awardId === "berton-house-writers-residency":
      finalistTag = "Residents";
      break;
    case awardId === "rbc-bronwen-wallace-award-for-emerging-writers":
      finalistTag = "Finalists";
      break;
    case awardId === "writers-trust-mentorship":
      finalistTag = customLabel || "Mentees";
      programData = data.programs.find(
        (p) =>
          parseInt(p.year) === parseInt(year) &&
          p.program.document[0].uid === awardId
      );
      break;
    case awardId === "rising-stars":
      finalistTag = customLabel || "Rising Stars";
      programData = data.programs.find(
        (p) =>
          parseInt(p.year) === parseInt(year) &&
          p.program.document[0].uid === awardId
      );
      break;
    default:
      finalistTag = "Finalists";
      break;
  }
  let finalistData = null;
  if (hasFinalists) {
    // console.log({ winner, hasFinalists, jurors: type === "jurors" });
    if (winner || type === "jurors") {
      finalistData = writer.data.award_finalist.find(
        (af) =>
          parseInt(af.year) === parseInt(year) &&
          af.award_link.document[0].uid === awardId
      );
      return (
        <Columns.Column
          mobile={{ size: 12 }}
          tablet={{ size: hasBook ? 4 : 2 }}
          desktop={{ size: hasBook ? 4 : 2 }}
          widescreen={{ size: hasBook ? 4 : 2 }}
        >
          <article
            className={cx({
              card: true,
              awardCard: true,
              winner: isWinner,
            })}
          >
            <Columns mobile>
              <Columns.Column
                mobile={{ size: hasBook ? 6 : 12 }}
                tablet={{ size: hasBook ? 6 : 12 }}
              >
                <div className={styles.info}>
                  {showFinalistTag && (
                    <span
                      className={styles.tag}
                      dangerouslySetInnerHTML={{
                        __html: `${finalistTag}
                      ${
                        finalistData &&
                        finalistData.award_subcategory &&
                        finalistData.award_subcategory.text &&
                        finalistData.award_subcategory.text !== `null`
                          ? ` - <em>${finalistData.award_subcategory.text}</em>`
                          : ``
                      }`,
                      }}
                    />
                  )}
                  <Link
                    to={`/${slug}/`}
                    title={`${title.text}`}
                    className={styles.awardCardImage}
                  >
                    {isWinner && showWinner !== false && (
                      <span
                        className={styles.tag}
                        dangerouslySetInnerHTML={{
                          __html:
                            customLabel ||
                            `Winner
                      ${
                        finalistData &&
                        finalistData.award_subcategory &&
                        finalistData.award_subcategory.text &&
                        finalistData.award_subcategory.text !== `null`
                          ? ` - <em>${finalistData.award_subcategory.text}</em>`
                          : ``
                      }`,
                        }}
                      />
                    )}

                    <figure>{renderFluidImage(carousel, null, {})}</figure>
                    {renderText(
                      title,
                      `h2`,
                      null,
                      {},
                      null,
                      null,
                      null,
                      false,
                      null,
                      hasBook,
                      hasBook && book.data.short_story === "true"
                    )}
                  </Link>
                </div>
              </Columns.Column>
              {hasBook && (
                <Columns.Column
                  mobile={{ size: hasBook ? 6 : 12 }}
                  tablet={{ size: hasBook ? 6 : 12 }}
                >
                  <div className={styles.info}>
                    <Link
                      to={`/${slug}/`}
                      title={`${title.text}`}
                      className={styles.name}
                    >
                      <figure>
                        {renderFluidImage(writer.data.image.carousel, null, {})}
                      </figure>
                      {book.data.authors &&
                      book.data.authors.length > 1 ? null : (
                        <h3>{`${data.first_name.text} ${data.last_name.text}`}</h3>
                      )}
                    </Link>
                    {book.data.authors && book.data.authors.length > 1 && (
                      <>
                        {book.data.authors
                          .map(({ author1 }) => {
                            let data = null;
                            if (
                              author1 &&
                              author1.document &&
                              author1.document[0].data &&
                              authorids.indexOf(author1.document[0].slug) === -1
                            ) {
                              authorids.push(author1.document[0].slug);
                              data = author1.document[0].data;
                              return (
                                <Link
                                  key={`${awardId}-${type}-${year}-${author1.document[0].slug}`}
                                  to={`/${author1.document[0].slug}`}
                                >
                                  <h3 style={{ marginBottom: `5px` }}>
                                    {data.first_name.text} {data.last_name.text}
                                  </h3>
                                </Link>
                              );
                            }
                            return null;
                          })
                          .filter(Boolean)}
                      </>
                    )}
                  </div>
                </Columns.Column>
              )}
            </Columns>
          </article>
        </Columns.Column>
      );
    } else {
      return (
        <Columns.Column
          mobile={{ size: 6 }}
          tablet={{ size: 2 }}
          desktop={{ size: 2 }}
          widescreen={{ size: 2 }}
        >
          <article
            className={cx({
              card: true,
              awardCard: true,
            })}
          >
            <div className={styles.info}>
              {showFinalistTag && (
                <span
                  className={styles.tag}
                  dangerouslySetInnerHTML={{
                    __html: customLabel || finalistTag,
                  }}
                />
              )}
              <Link
                to={`/${slug}/`}
                title={`${title.text}`}
                className={styles.name}
              >
                <figure>
                  {renderFluidImage(
                    alwaysShowAuthor ? writer.data.image.carousel : carousel,
                    null,
                    {}
                  )}
                </figure>
                {renderText(
                  title,
                  `h2`,
                  null,
                  {},
                  null,
                  null,
                  null,
                  false,
                  null,
                  hasBook,
                  hasBook && book.data.short_story === "true"
                )}
                {hasBook &&
                  type !== "jurors" &&
                  awardId !== `berton-house-writers-residency` &&
                  (book.data.authors && book.data.authors.length > 1 ? null : (
                    <h3>
                      {data.first_name.text} {data.last_name.text}
                    </h3>
                  ))}
              </Link>
              {hasBook && book.data.authors && book.data.authors.length > 1 && (
                <>
                  {book.data.authors
                    .map(({ author1 }) => {
                      let data = null;

                      if (
                        author1 &&
                        author1.document &&
                        author1.document[0].data &&
                        authorids.indexOf(author1.document[0].slug) === -1
                      ) {
                        data = author1.document[0].data;
                        authorids.push(author1.document[0].slug);
                        return (
                          <Link
                            key={`${awardId}-${type}-${year}-${author1.document[0].slug}`}
                            to={`/${author1.document[0].slug}`}
                          >
                            <h3 style={{ marginBottom: `5px` }}>
                              {data.first_name.text} {data.last_name.text}
                            </h3>
                          </Link>
                        );
                      }
                      return null;
                    })
                    .filter(Boolean)}
                </>
              )}
            </div>
          </article>
        </Columns.Column>
      );
    }
  } else {
    const { image, first_name, last_name } = data;
    let sworks = data.selected_works
      ? data.selected_works.filter(
          (swork) =>
            swork.primary?.award?.document[0].uid === awardId &&
            swork.primary?.year === year
        )
      : [];
    if (sworks.length) {
      sworks = sworks[0];
    }
    const { carousel } = image;
    if (finalistTag && finalistTag.charAt(finalistTag.length - 1) === "s") {
      finalistTag = finalistTag.substr(0, finalistTag.length - 1);
    }
    return (
      <React.Fragment>
        <Columns.Column
          mobile={{ size: 12 }}
          tablet={{ size: 2 }}
          desktop={{ size: 2 }}
        >
          <article
            className={cx({
              card: true,
              awardCard: true,
              authorCard: true,
              winner: showWinner,
              padded: true,
            })}
          >
            <Columns mobile>
              <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
                <Link
                  to={`/${slug}/`}
                  title={`${first_name.text} ${last_name.text}`}
                >
                  <div className={styles.info}>
                    <span className={styles.tag}>
                      {awardId === "margaret-laurence-lecture-series"
                        ? "Lecturer"
                        : customLabel || finalistTag || "Winner"}
                    </span>
                    <figure>
                      {renderFluidImage(carousel, null, {}, "writer")}
                    </figure>
                    <h2
                      className={styles.name}
                    >{`${first_name.text} ${last_name.text}`}</h2>
                  </div>
                </Link>
              </Columns.Column>
            </Columns>
          </article>
        </Columns.Column>
        {sworks && sworks.items && sworks.items.length > 0 && (
          <Columns.Column
            mobile={{ size: 12 }}
            tablet={{ size: 10 }}
            desktop={{ size: 10 }}
          >
            <article
              className={cx({
                card: true,
                awardCard: true,
                compressed: true,
              })}
            >
              <div className={styles.info}>
                <Columns mobile multiline>
                  {sworks.items.map((s, idx) => {
                    if (!s) {
                      return null;
                    }
                    const { book } = s;
                    if (!book) {
                      return null;
                    }
                    const bdoc = book.document[0];
                    const { title, cover_image } = bdoc.data;
                    const { carousel } = cover_image;
                    return (
                      <Columns.Column
                        key={`${slug}-selected-${idx}`}
                        mobile={{ size: 4 }}
                        tablet={{ size: `one-fifth` }}
                        widescreen={{ size: `one-fifth` }}
                      >
                        {idx === 0 && (
                          <span className={styles.tag}>Selected Works</span>
                        )}
                        <article style={{ padding: `0 1rem 1rem 1rem` }}>
                          <Link to={`/${slug}/`} title={`${title.text}`}>
                            <figure>
                              {renderFluidImage(carousel, null, {}, "book")}
                            </figure>
                          </Link>
                          <Link
                            to={`/${slug}/`}
                            title={`${title.text}`}
                            className={styles.name}
                          >
                            {renderText(
                              title,
                              `h3`,
                              styles.smallTitle,
                              {},
                              null,
                              null,
                              null,
                              false,
                              null,
                              true,
                              bdoc.data.short_story === "true"
                            )}
                          </Link>
                        </article>
                      </Columns.Column>
                    );
                  })}
                </Columns>
              </div>
            </article>
          </Columns.Column>
        )}
      </React.Fragment>
    );
  }
};

export default class WritersBooksGrid extends PureComponent {
  static CELL_ID = 0;
  constructor(props) {
    super(props);
    this.state = { cellCount: this.getNumCells() };
  }
  componentDidMount() {
    BreakpointListener.on(events.breakpoint, this.onBreakpoint);
  }

  componentWillUnmount() {
    BreakpointListener.off(events.breakpoint, this.onBreakpoint);
  }

  onBreakpoint = () => {
    this.setState({ cellCount: this.getNumCells() });
  };

  getRow(key, items, heading) {
    const { type } = this.props;
    const CellRenderer = type === "book" ? BookRenderer : WriterRenderer;
    if (!items || items.length === 0) {
      return null;
    }

    return (
      <LazyLoad height={500} offset={600} key={key} /*overflow*/>
        <Columns mobile multiline>
          {heading && (
            <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
              {<h3 className={styles.subheading}>{heading}</h3>}
            </Columns.Column>
          )}
          {items.map((item) => {
            WritersBooksGrid.CELL_ID++;
            return (
              <CellRenderer key={`row-${WritersBooksGrid.CELL_ID}`} {...item} />
            );
          })}
        </Columns>
      </LazyLoad>
    );
  }

  getAwardRow(key, list, heading, yearToShow = null, awardId, type) {
    let renderList = [];
    let years = Object.keys(list);
    let hasSelectedWinner = false;

    years.reverse();
    years.forEach((year) => {
      let breakRows = [];
      let hasBreak = false;
      if (
        yearToShow &&
        yearToShow !== "all" &&
        year.toString() !== yearToShow.toString()
      ) {
        return;
      }
      let aList = list[year];
      let hasWinner =
        type === "jurors" ? false : aList.filter((a) => a.winner).length > 0;
      if (type === "jurors") {
        aList.sort((a, b) => {
          const aname = a.writer.sort_title;
          const bname = b.writer.sort_title;
          if (aname > bname) {
            return 1;
          }
          if (bname > aname) {
            return -1;
          }
          return 0;
        });
      } else {
        aList.sort(
          firstBy((a) => {
            let programData = a?.writer?.data?.programs?.find(
              (p) =>
                parseInt(p.year) === parseInt(year) &&
                p.program.document[0].uid === awardId
            );
            let finalistData = a?.writer?.data?.award_finalist?.find(
              (af) =>
                parseInt(af.year) === parseInt(year) &&
                af.award_link.document[0].uid === awardId
            );
            let customLabel = null;
            if (finalistData) {
              if (
                finalistData &&
                finalistData.award_subcategory &&
                finalistData.award_subcategory.text &&
                finalistData.award_subcategory.text !== `null`
              ) {
                customLabel = `${a.winner ? `Winner` : `Finalists`} - <em>${
                  finalistData.award_subcategory.text
                }</em>`;
                a.customLabel = customLabel;
                if (
                  (hasWinner &&
                    customLabel === `Finalists - <em>Short Fiction</em>`) ||
                  customLabel === `Finalists - <em>Poetry</em>`
                ) {
                  breakRows.push(customLabel);
                } else if (!hasWinner) {
                  breakRows.push(customLabel);
                }
              } else {
                customLabel = a.winner ? `Winner` : `Finalists`;
                a.customLabel = customLabel;
                breakRows.push(customLabel);
              }
              if (customLabel) {
                if (customLabel === `Winner` || customLabel === `Finalists`) {
                  if (a.winner) {
                    return -1;
                  } else {
                    return 0;
                  }
                } else {
                  if (customLabel === `Winner - <em>Short Fiction</em>`) {
                    return -4;
                  } else if (customLabel === `Winner - <em>Poetry</em>`) {
                    return -3;
                  } else if (
                    customLabel === `Finalists - <em>Short Fiction</em>`
                  ) {
                    return -2;
                  } else if (customLabel === `Finalists - <em>Poetry</em>`) {
                    return -1;
                  }
                }
              }

              return 0;
            }
            if (programData) {
              a.isProgram = true;
              customLabel = programData?.custom_label?.toLowerCase();
              if (customLabel) {
                switch (customLabel) {
                  case "mentor":
                  case "selector":
                    a.customLabel = `${programData?.custom_label}s`;
                    a.winner = false;
                    breakRows.push(`${programData?.custom_label}s`);
                    break;
                  case "mentee":
                    a.customLabel = "Mentees";
                    a.winner = false;
                    break;
                  case "rising star":
                    a.customLabel = "Rising Stars";
                    a.winner = false;
                    break;
                }
                if (customLabel === `mentor` || customLabel === `selector`) {
                  return 0;
                }
                return -1;
              }
              if (a?.writer?.data?.program) return a.winner ? -1 : 0;
              return a.winner ? -1 : 0;
            } else {
              return a.winner ? -1 : 0;
            }
          }).thenBy((a, b) => {
            const aname = a.writer.sort_title;
            const bname = b.writer.sort_title;
            if (aname > bname) {
              return 1;
            }
            if (bname > aname) {
              return -1;
            }
            return 0;
          })
        );
      }

      let ids = [];
      breakRows = Array.from(new Set(breakRows));
      renderList.push(
        <Columns.Column
          key={`awardrow-${renderList.length}`}
          mobile={{ size: 12 }}
          tablet={{ size: 12 }}
        >
          <Columns mobile multiline>
            <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
              {<h4 className={styles.yearHeading}>{year}</h4>}
            </Columns.Column>
            {aList
              .map((item, idx) => {
                if (
                  ids.indexOf(item.book ? item.book.id : item.writer.id) > -1
                ) {
                  return null;
                }
                ids.push(item.book ? item.book.id : item.writer.id);
                WritersBooksGrid.CELL_ID++;
                let spacer = null;
                if (breakRows.length && breakRows.includes(item?.customLabel)) {
                  breakRows = breakRows.filter((br) => br !== item.customLabel);
                  hasBreak = true;
                  spacer = (
                    <div
                      className="column is-12"
                      style={{
                        height: 1,
                        display: "block",
                      }}
                    />
                  );
                }
                const isWinner = item.winner === true;
                return (
                  <React.Fragment key={`row-${WritersBooksGrid.CELL_ID}`}>
                    {spacer}
                    <AwardRenderer
                      showWinnerTag={isWinner || (hasWinner && idx === 0)}
                      isWinner={isWinner}
                      showFinalistTag={isWinner || idx === 0 || spacer}
                      year={year}
                      awardId={awardId}
                      hasFinalists={aList.length > 1}
                      type={type}
                      debug={
                        year &&
                        (year.toString() === "2021" ||
                          year.toString() === "2020")
                      }
                      showWinner={isWinner}
                      {...item}
                    />
                  </React.Fragment>
                );
              })
              .filter(Boolean)}
          </Columns>
        </Columns.Column>
      );
    });

    if (!renderList || renderList.length === 0) {
      return null;
    }

    return (
      <LazyLoad height={500} offset={600} key={key} /*overflow*/>
        <Columns mobile multiline>
          {heading && (
            <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
              {<h3 className={styles.subheading}>{heading}</h3>}
            </Columns.Column>
          )}
          {renderList}
        </Columns>
      </LazyLoad>
    );
  }

  getRowHeight() {
    return 500;
  }

  getNumCells() {
    switch (true) {
      case BreakpointListener.size === "mobile":
        return 2;
      case BreakpointListener.size === "tablet":
      case BreakpointListener.size === "desktop":
        return 4;
      case BreakpointListener.size === "widescreen":
      case BreakpointListener.size === "fullhd":
      default:
        return 6;
    }
  }

  renderList(list) {
    if (!list) return null;
    let grid = [];
    let array = Array.from(list);
    let i,
      j,
      temparray,
      chunk = this.state.cellCount;
    let count = 0;
    for (i = 0, j = list.length; i < j; i += chunk) {
      temparray = array.slice(i, i + chunk);
      grid.push(this.getRow(`row-${count}`, temparray));
      count++;
    }
    return grid;
  }

  renderObjectList(list) {
    let grid = [];
    if (!list) return null;
    let rowNames = Object.keys(list);

    rowNames.forEach((key, i) => {
      grid.push(this.getRow(`row-${i}`, list[key].list, list[key].heading));
    });
    return grid;
  }

  renderAwardsList(list, isSingleAward, year, isProgram, type) {
    let grid = [];
    if (!list) return null;
    if (isSingleAward) {
      grid.push(
        this.getAwardRow(
          `row-${list.name}`,
          list.list,
          list.name,
          year,
          list.uid,
          type
        )
      );
    } else {
      let rowNames = Object.keys(list);
      let sortArray = isProgram ? programsOrder : awardsOrder;
      rowNames.sort(function (a, b) {
        return sortArray.indexOf(a) - sortArray.indexOf(b);
      });
      rowNames.forEach((key, i) => {
        grid.push(
          this.getAwardRow(
            `row-${i}`,
            list[key].list,
            list[key].name,
            year,
            list[key].uid,
            type
          )
        );
      });
    }

    return grid.filter(Boolean);
  }

  componentDidUpdate() {
    if (!this.grid) {
      return;
    }
    setTimeout(() => {
      this.grid.scrollTop = 0;
    }, 1);
  }

  render() {
    const { list, heading, awardsList, isSingleAward, year, type, isProgram } =
      this.props;
    const listType = awardsList
      ? "object"
      : Array.isArray(list)
      ? "array"
      : "object";

    WritersBooksGrid.CELL_ID = 0;

    return (
      <Section className={`white ${cx({ section: true, white: true })}`}>
        <Container>
          <Columns mobile multiline>
            {heading && (
              <Columns.Column mobile={{ size: 12 }} tablet={{ size: 12 }}>
                {<h4>{heading}</h4>}
              </Columns.Column>
            )}
            <div
              className={`column is-12-mobile is-12-tablet ${cx({
                grid: true,
              })}`}
              ref={(r) => (this.grid = r)}
            >
              {awardsList
                ? this.renderAwardsList(
                    awardsList,
                    isSingleAward,
                    year,
                    isProgram,
                    type
                  )
                : list && listType === "array"
                ? this.renderList(list)
                : this.renderObjectList(list)}
            </div>
          </Columns>
        </Container>
      </Section>
    );
  }
}
