let awardsOrder = [
  "hilary-weston-writers-trust-prize-for-nonfiction",
  "rogers-writers-trust-fiction-prize",
  "shaughnessy-cohen-prize-for-political-writing",
  "matt-cohen-award",
  "latner-writers-trust-poetry-prize",
  "vicky-metcalf-award-for-literature-for-young-people",
  "writers-trust-engel-findley-award",
  "rbc-bronwen-wallace-award-for-emerging-writers",
  "writers-trust-mcclelland-stewart-journey-prize",
  "dayne-ogilvie-prize",
];

let programsOrder = [
  "writers-trust-mentorship",
  "writers-trust-fellowship",
  "berton-house-writers-residency",
  "margaret-laurence-lecture-series",
  "rising-stars",
];

export { awardsOrder, programsOrder };
