import React, { Component } from "react";
import { awardsOrder, programsOrder } from "./sortOrders";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Footer from "components/Footer";
import FullWidthCallouts from "slices/FullWidthCallouts";
import Helmet from "react-helmet";
import Hero from "slices/Hero";
import Route from "route-parser";
import WritersBooksFilter from "components/WritersBooksFilter";
import WritersBooksGrid from "components/WritersBooksGrid";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { firstBy } from "thenby";
import { graphql } from "gatsby";
import { navigate } from "gatsby";
import posed from "react-pose";
import { sine } from "utils/easing";
import { smoothScrollToTop } from "utils/smoothScrollTo";
import styles from "./WritersBooks.module.scss";
import { sliceTypeMatches } from "utils/slices";

// Slices

// graphql

let awards;
let writers;

let books;
let booksByAuthor;
let awardsByYear;
let jurorsByYear;

let programs;
let programsByYear;
let events;

let allAwardYears = [];
let allProgramYears = [];
let bookSortYears = null;

const getAwardsByYear = (writersRef, booksRef) => {
  let writers = Array.from(writersRef);
  let awards = {};
  let books = {};

  booksRef.forEach((b) => {
    books[b.uid] = b;
  });

  writers.forEach((w) => {
    let flist = w.data.award_finalist;
    let slist = w.data.selected_works;

    if (flist) {
      flist.forEach((a) => {
        if (!a.award_link || !a.award_link.document) {
          return;
        }

        let uid = a.award_link.document[0].uid;
        let year = a.year;
        let book = a.book;

        if (!awards[uid]) {
          awards[uid] = {
            uid,
            name: a.award_link.document[0].data.title.text,
            years: [],
            list: {},
          };
        }
        if (awards[uid].years.indexOf(year) === -1) {
          awards[uid].years.push(year);
          allAwardYears.push(year);
        }
        if (!awards[uid].list[year]) {
          awards[uid].list[year] = [];
        }
        awards[uid].list[year].push({
          winner: a.won === "yes",
          writer: w,
          book: book && book.document ? books[book.document[0].uid] : null,
        });
      });
    }
    if (slist && slist.length) {
      slist.forEach((a) => {
        if (
          !a ||
          !a.primary ||
          !a.primary.award ||
          !a.primary.award.document ||
          !a.primary.award.document[0] ||
          !a.primary.award.document[0].type !== "award_page"
        ) {
          return;
        }
        let uid = a.primary.award.document[0].uid;
        let year = a.primary.year;
        if (!awards[uid]) {
          awards[uid] = {
            uid,
            name: a.primary.award.document[0].data.title.text,
            list: {},
            years: [],
          };
        }
        if (year !== undefined && awards[uid].years.indexOf(year) === -1) {
          awards[uid].years.push(year);
        }

        if (!awards[uid].list[year]) {
          awards[uid].list[year] = [];
        }
        let existingWriter = awards[uid].list[year].filter(
          (item) => item.writer.uid === w.uid
        )[0];
        let items = a.items
          .map(({ book }) => (book ? books[book.document[0].uid] : null))
          .filter(Boolean);
        if (existingWriter) {
          existingWriter.list = items;
        } else {
          awards[uid].list[year].push({
            writer: w,
            winner: false,
            list: items,
          });
        }
        awards[uid].years = awards[uid].years.filter(Boolean);
      });
    }
  });
  allAwardYears = Array.from(new Set(allAwardYears));
  allAwardYears.sort((a, b) => {
    return parseInt(b) - parseInt(a);
  });

  return awards;
};

const getProgramsByYear = (writersRef) => {
  let writers = Array.from(writersRef);
  let awards = {};

  writers.forEach((w) => {
    let flist = w.data.programs;
    let slist = w.data.selected_works;
    let found = false;

    if (flist && flist.length > 0) {
      flist.forEach((a) => {
        if (!a.program || !a.program.document) {
          return;
        }

        let uid = a.program.document[0].uid;
        let year = a.year;

        if (!awards[uid]) {
          awards[uid] = {
            uid,
            name: a.program.document[0].data.title.text,
            years: [],
            list: {},
          };
        }
        if (awards[uid].years.indexOf(year) === -1) {
          awards[uid].years.push(year);
          allProgramYears.push(year);
        }
        if (!awards[uid].list[year]) {
          awards[uid].list[year] = [];
        }
        awards[uid].list[year].push({
          writer: w,
        });
        found = true;
      });
    }

    if (found && slist && slist.length) {
      slist.forEach((a) => {
        if (
          !a ||
          !a.primary ||
          !a.primary.award ||
          !a.primary.award.document ||
          !a.primary.award.document[0] ||
          a.primary.award.document[0].type === "award_page"
        ) {
          return;
        }
        let uid = a.primary.award.document[0].uid;
        let year = a.primary.year;
        if (!awards[uid]) {
          awards[uid] = {
            uid,
            name: a.primary.award.document[0].data.title.text,
            list: {},
            years: [],
          };
        }
        if (awards[uid].years.indexOf(year) === -1) {
          awards[uid].years.push(year);
        }

        if (!awards[uid].list[year]) {
          awards[uid].list[year] = [];
        }
        let existingWriter = awards[uid].list[year].filter((item) => {
          return (item) => item.writer.uid === w.uid;
        })[0];
        let items = a.items
          .map(({ book }) => (book ? books[book.document[0].uid] : null))
          .filter(Boolean);
        if (existingWriter) {
          existingWriter.list = items;
        } else {
          awards[uid].list[year].push({
            list: items,
          });
        }
      });
    }
  });

  allProgramYears = Array.from(new Set(allProgramYears));
  allProgramYears.sort((a, b) => {
    return parseInt(b) - parseInt(a);
  });
  return awards;
};

const getBooksByAuthor = (books) => {
  let arr = Array.from(books);
  let authors = {};
  writers.forEach((a) => {
    authors[a.uid] = {
      heading: `${a.data.first_name.text} ${a.data.last_name.text}`,
      list: [],
    };
  });
  arr.forEach((book) => {
    let bookAuthors = book.data.authors.map((a) => a.author1);
    bookAuthors.forEach((a) => {
      if (!a) {
        return;
      }
      let uid = a.raw.uid;
      if (!authors[uid]) {
        return;
      }
      authors[uid].list.push(book);
    });
  });
  Object.keys(authors).forEach((key) => {
    if (!authors[key].list.length) delete authors[key];
  });
  return authors;
};

const getAwardYearsToShow = (awards) => {
  if (!awards) {
    return null;
  }
  let years = [{ name: "All", value: "all" }];
  let hasMultiple = awards.name === undefined;
  if (hasMultiple) {
    years = years
      .concat(
        allAwardYears.map((y) =>
          y ? { name: y.toString(), value: parseInt(y) } : null
        )
      )
      .filter(Boolean);
  } else {
    awards.years.sort((a, b) => {
      return parseInt(b) - parseInt(a);
    });
    years = years
      .concat(
        awards.years.map((y) =>
          y ? { name: y.toString(), value: parseInt(y) } : null
        )
      )
      .filter(Boolean);
  }
  return years;
};

const getProgramYearsToShow = (programs) => {
  if (!programs) {
    return null;
  }
  let years = [{ name: "All", value: "all" }];
  let hasMultiple = programs.name === undefined;
  if (hasMultiple) {
    years = years
      .concat(
        allProgramYears.map((y) =>
          y ? { name: y.toString(), value: parseInt(y) } : null
        )
      )
      .filter(Boolean);
  } else {
    programs.years.sort((a, b) => {
      return parseInt(b) - parseInt(a);
    });
    years = years
      .concat(
        programs.years.map((y) =>
          y ? { name: y.toString(), value: parseInt(y) } : null
        )
      )
      .filter(Boolean);
  }
  return years;
};

const getJurorsByYear = (writers) => {
  let result = {};
  writers.forEach((w) => {
    let jury = w.data.jury;
    if (jury && jury.length) {
      jury.forEach((j) => {
        if (j.award && j.award.document && j.award.document.length > 0) {
          if (j.award.document[0].uid === "matt-cohen-award") {
            return;
          }
          if (!result[j.award.document[0].uid]) {
            result[j.award.document[0].uid] = {
              uid: j.award.document[0].uid,
              name: j.award.document[0].data.title.text,
              years: [],
              list: {},
            };
          }

          if (!result[j.award.document[0].uid].years.indexOf(j.year)) {
            result[j.award.document[0].uid].years.push(j.year);
          }
          if (!result[j.award.document[0].uid].list[j.year]) {
            result[j.award.document[0].uid].list[j.year] = [];
          }
          result[j.award.document[0].uid].list[j.year].push({ writer: w });
        }
      });
    }
  });
  return result;
};

const ScrollToTopButton = posed.button({
  on: {
    x: 0,
    transition: {
      type: `tween`,
      ease: sine.out,
      duration: 300,
    },
  },
  off: {
    x: `101%`,
    transition: {
      type: `tween`,
      ease: sine.out,
      duration: 200,
    },
  },
});

class WritersBooksPage extends Component {
  state = { showScrollToTop: false };

  static getDerivedStateFromProps(props, state) {
    if (!bookSortYears) {
      bookSortYears = props.pageContext.bookSortYears;
    }
    if (!awards) {
      awards = props.data.awards.edges.map((edge) => edge.node);
      awards.sort(function (a, b) {
        return awardsOrder.indexOf(a.uid) - awardsOrder.indexOf(b.uid);
      });
    }

    if (!programs) {
      programs = props.data.programs.edges.map((edge) => edge.node);
      events = props.data.events.edges.map((edge) => edge.node);
      programs = programs.concat(events);
      programs.sort(function (a, b) {
        return programsOrder.indexOf(a.uid) - programsOrder.indexOf(b.uid);
      });
    }

    if (!writers) {
      writers = props.pageContext.writers.edges.map((edge) => edge.node);
    }

    if (!jurorsByYear) {
      jurorsByYear = getJurorsByYear(writers);
    }

    if (!books) {
      books = props.pageContext.books.edges.map((edge) => edge.node);
    }

    if (!booksByAuthor) {
      booksByAuthor = getBooksByAuthor(books);
    }

    if (!awardsByYear) {
      awardsByYear = getAwardsByYear(writers, books);
    }

    if (!programsByYear) {
      programsByYear = getProgramsByYear(writers);
      // delete programsByYear['margaret-laurence-lecture-series'];
      for (let i = programs.length - 1; i >= 0; i--) {
        if (!programsByYear[programs[i].uid]) {
          programs.splice(i, 1);
        }
      }
    }

    let wbRouteParser = new Route(
      `/writers-books/:showing(/:sort)(/:year)(/:type)(/)`
    );
    let { showing, sort, year, type } = wbRouteParser.match(
      props.location.pathname
    );

    if (!showing || showing === "") {
      showing = "authors";
    }

    if (showing === "authors" && !(sort || sort === "")) {
      sort = "recent";
    }

    if (showing === "books" && !(sort || sort === "")) {
      sort = "recent";
    }

    if (
      (showing === "awards" || showing === "programs") &&
      !(sort || sort === "")
    ) {
      sort = "all";
    }

    if (!year && showing === "awards") {
      year = "all";
    }

    if (!year && showing === "programs") {
      year = "all";
    }

    if ((!type && showing === "awards") || sort === "matt-cohen-award") {
      type = "finalists";
    }

    let heading = "";

    if (props.location.pathname !== state.pathname) {
      switch (showing) {
        case "authors":
          heading = "Recent";
          if (sort === "a-z") {
            heading = "A-Z";
            writers.sort((a, b) => {
              const aname = a.sort_title;
              const bname = b.sort_title;
              if (aname > bname) {
                return 1;
              }
              if (bname > aname) {
                return -1;
              }
              return 0;
            });
          } else {
            writers.sort(
              firstBy((a, b) => {
                return b.sort_year - a.sort_year;
              }).thenBy(() => {
                return Math.random() > 0.5 ? 1 : -1;
              })
            );
          }
          return {
            showing,
            sort,
            pathname: props.location.pathname,
            isWriters: true,
            writers: writers.filter(
              (w) => w.data.show_in_writers_books !== `no`
            ),
            heading,
            isProgram: false,
            awards: null,
            books: null,
            yearsToShow: null,
            type: "finalists",
          };
        case "books":
          let sBooks = books;
          heading = "Recent";
          if (sort === "title") {
            heading = "Works by Title";
            sBooks.sort((a, b) => {
              if (a.sort_title === b.sort_title) {
                return 0;
              }
              return b.sort_title < a.sort_title ? 1 : -1;
            });
          } else if (sort === "author") {
            sBooks = booksByAuthor;
            heading = "Works by Author";
          } else {
            sBooks.sort(
              firstBy((a, b) => {
                return (
                  (bookSortYears[b.uid] || 0) - (bookSortYears[a.uid] || 0)
                );
              }).thenBy(() => {
                return Math.random() > 0.5 ? 1 : -1;
              })
            );
          }
          return {
            showing,
            sort,
            pathname: props.location.pathname,
            isWriters: false,
            books: sBooks,
            heading,
            isProgram: false,
            writers: null,
            awards: null,
            isSingleAward: null,
            yearsToShow: null,
            type: "book",
          };
        case "awards":
          heading = "Awards";
          let sAwards = awardsByYear;
          let sJurors = jurorsByYear;

          if (sort !== "all") {
            heading = type === "jurors" ? "Jurors by Year" : "Awards by Year";
            sAwards = awardsByYear[sort];
            sJurors = jurorsByYear[sort];
          }
          return {
            showing,
            sort,
            pathname: props.location.pathname,
            isWriters: false,
            awards: sAwards,
            jurors: sJurors,
            isProgram: false,
            isSingleAward: sort !== "all",
            yearsToShow: getAwardYearsToShow(sAwards),
            heading,
            books: null,
            writers: null,
            year,
            type,
          };
        case "programs":
          heading = "Programs";
          let sPrograms = programsByYear;

          if (sort !== "all") {
            heading = "Programs by Year";
            sPrograms = programsByYear[sort];
          }
          return {
            showing,
            sort,
            pathname: props.location.pathname,
            isWriters: false,
            awards: sPrograms,
            isProgram: true,
            isSingleAward: sort !== "all",
            yearsToShow: getProgramYearsToShow(sPrograms),
            heading,
            books: null,
            writers: null,
            year,
          };
        default:
          break;
      }
    }
    return {
      pathname: props.location.pathname,
      showing,
      sort,
      year,
    };
  }

  static getPageMeta(globals, page, location) {
    let title = page.seo_title || page.title;
    let description = page.seo_description || globals.seo_description;
    let img_alt = page.seo_image_alt || globals.seo_image_alt || page.title;
    let fb_image = page.seo_image
      ? page.seo_image.facebook
      : globals.seo_image.facebook;

    let twitter_image = page.seo_image
      ? page.seo_image.twitter
      : globals.seo_image.twitter;

    let pageTitle = `${title.text} | Writers' Trust of Canada`;
    let meta = [
      { property: `og:site_name`, content: pageTitle },
      { name: `title`, content: title.text },
      { property: `og:title`, content: title.text },
      { name: `description`, content: description.text },
      { property: `og:description`, content: description.text },
      { property: `og:image`, content: fb_image.url },
      {
        property: `og:image:width`,
        content: fb_image.dimensions ? fb_image.dimensions.width : null,
      },
      {
        property: `og:image:height`,
        content: fb_image.dimensions ? fb_image.dimensions.height : null,
      },
      { property: `og:image:alt`, content: img_alt.text },
      {
        property: `og:url`,
        content: `https://www.writerstrust.com${location.pathname}`,
      },
      {
        name: `twitter:card`,
        content: `summary_large_image`,
      },
      {
        name: `twitter:image`,
        content: twitter_image.url,
      },
    ];
    return meta.filter((m) => m.content);
  }

  componentDidMount() {
    setTimeout(() => {
      // window.scrollTo(0, 0);
      window.addEventListener("scroll", this.onScroll);
    }, 0);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    if (window.scrollY > 1000) {
      if (!this.state.showScrollToTop) {
        this.setState({ showScrollToTop: true });
      }
    } else {
      if (this.state.showScrollToTop) {
        this.setState({ showScrollToTop: false });
      }
    }
  };

  scrollToTop = () => {
    smoothScrollToTop();
  };

  onFilterChange = ({ showing, sort, year, type }) => {
    const base = `/writers-books`;
    let loc = `${base}`;
    if (showing) {
      loc = `${loc}/${showing}`;
    }
    if (!sort) {
      switch (showing) {
        case "authors":
          sort = "recent";
          break;
        case "books":
          sort = "recent";
          break;
        case "awards":
        default:
          sort = "all";
          break;
      }
    }
    loc = `${loc}/${sort}`;
    if (!year && (showing === "awards" || showing === "programs")) {
      year = "all";
    }

    if (year) {
      loc = `${loc}/${year}`;
    }

    if (showing === "awards") {
      if (!type || sort === "matt-cohen-award") {
        type = "finalists";
      }
      loc = `${loc}/${type}`;
    }

    navigate(`${loc}/`, { state: { internal: true } });
  };

  renderSlice = (slice, nextSlice) => {
    if (!slice) {
      return null;
    }

    let result = null;
    let Cmp = null;
    let extras = {};
    const slice_type = slice.slice_type;
    switch (true) {
      case sliceTypeMatches(slice_type, "hero"):
        extras.bottomTheme =
          nextSlice && nextSlice.primary && nextSlice.primary.theme
            ? nextSlice.primary.theme
            : null;
        slice.primary.show_side_panel = true;
        extras.bottomCompressed = true;
        extras.bottomRenderChildren = (
          <WritersBooksFilter
            awards={awards}
            programs={programs}
            years={this.state.yearsToShow}
            showing={this.state.showing}
            sort={this.state.sort}
            year={this.state.year}
            type={this.state.type}
            onChange={this.onFilterChange}
          />
        );
        Cmp = Hero;
        break;
      case sliceTypeMatches(slice_type, "full_width_callouts"):
        Cmp = FullWidthCallouts;
        break;
      case sliceTypeMatches(slice_type, "full_width_callout_list"):
        let items = slice.items;
        if (items.length && !items[0].link) {
          items = slice.items
            .map((item) => {
              return item && item.callout && item.callout.document
                ? item.callout.document[0].data
                : null;
            })
            .filter(Boolean);
        }
        slice.items = items;
        slice.primary = { ...slice.primary, theme: "white" };
        Cmp = FullWidthCallouts;
        break;
      default:
        return null;
    }

    if (!Cmp && !result) {
      return null;
    }
    if (!Cmp && result) {
      return result;
    }
    return <Cmp {...slice} key={slice.id} {...extras} />;
  };

  shouldComponentUpdate(props) {
    if (props.location.pathname.indexOf(props.data.page.slug) === -1) {
      return false;
    }
    return true;
  }

  render() {
    const { page } = this.props.data;
    const { globals, footerData } = this.props.pageContext;
    const { uid } = page;
    const { title, body } = page.data;
    const {
      isWriters,
      writers,
      books,
      heading,
      awards,
      jurors,
      isSingleAward,
      isProgram,
      year,
      type,
      showScrollToTop,
    } = this.state;

    return (
      <React.Fragment>
        <Helmet
          bodyAttributes={{
            class:
              uid === "awards" || uid === "events" || uid === "programs"
                ? "landing-page"
                : "page",
          }}
          title={`${title.text} | Writers' Trust of Canada`}
          meta={WritersBooksPage.getPageMeta(
            globals.data,
            this.props.data.page.data,
            this.props.location
          )}
        />
        {body[0] && this.renderSlice(body[0])}
        <WritersBooksGrid
          heading={heading || null}
          list={isWriters ? writers : books}
          awardsList={type === "jurors" ? jurors : awards}
          isSingleAward={isSingleAward}
          isProgram={isProgram}
          year={year}
          type={type}
        />
        {body[1] && this.renderSlice(body[1])}
        <Footer data={footerData} theme={page.data.footer_theme} />
        <ScrollToTopButton
          ref={(r) => (this.stb = r)}
          className={styles.scrollToTopButton}
          onClick={this.scrollToTop}
          pose={showScrollToTop ? `on` : `off`}
        >
          <span>Back to top</span>
          <i>
            <FontAwesomeIcon icon={faArrowUp} />
          </i>
        </ScrollToTopButton>
      </React.Fragment>
    );
  }
}

export const query = graphql`
  query ($uid: String) {
    page: prismicLandingPage(uid: { eq: $uid }) {
      uid
      slug
      data {
        seo_title {
          text
        }
        seo_description {
          text
        }
        seo_image {
          twitter {
            url
            dimensions {
              width
              height
            }
          }
          facebook {
            url
            dimensions {
              width
              height
            }
          }
        }
        title {
          text
        }
        footer_theme
        body {
          ... on PrismicLandingPageBodyHero {
            id
            slice_type
            primary {
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              category {
                text
              }
              heading {
                text
              }
              hero_paragraph {
                html
              }
              button_label {
                text
              }
              button_link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
              bottom_callout_heading {
                text
              }
              bottom_callout_large_heading {
                text
              }
              bottom_callout_paragraph {
                html
                text
              }
              bottom_callout_button_label {
                text
              }
              bottom_callout_button_link {
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
              show_side_panel
              theme
              enable_footer
              enable_floating_side_panel
              show_footer_item_borders
              footer_heading {
                text
              }
            }
            items {
              item_size
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              heading {
                text
                html
              }
              anchor_id
              link {
                url
                raw {
                  link_type
                  slug
                  uid
                  id
                }
              }
            }
          }
          ... on PrismicLandingPageBodyFullWidthCallouts {
            id
            slice_type
            primary {
              theme
            }
            items {
              link {
                raw {
                  link_type
                  target
                  slug
                  id
                  uid
                }
              }
              image {
                url
                dimensions {
                  width
                  height
                }
              }
              category_heading {
                text
              }
              heading {
                text
              }
              label {
                text
              }
            }
          }
          ... on PrismicLandingPageBodyFullWidthCalloutList {
            id
            slice_type
            primary {
              theme
            }
            items {
              callout {
                document {
                  data {
                    link {
                      raw {
                        link_type
                        slug
                        id
                        uid
                      }
                    }
                    image {
                      url
                      dimensions {
                        width
                        height
                      }
                    }
                    category_heading {
                      text
                    }
                    heading {
                      text
                    }
                    label {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    awards: allPrismicAwardPage(
      filter: { uid: { regex: "/^((?!_template).)*$/" } }
    ) {
      edges {
        node {
          slug
          uid
          data {
            title {
              text
            }
          }
        }
      }
    }
    programs: allPrismicProgramPage(
      filter: { uid: { regex: "/^((?!_template).)*$/" } }
    ) {
      edges {
        node {
          slug
          uid
          data {
            title {
              text
            }
          }
        }
      }
    }
    events: allPrismicEventPage(
      filter: { uid: { regex: "/^((?!_template).)*$/" } }
    ) {
      edges {
        node {
          slug
          uid
          data {
            title {
              text
            }
          }
        }
      }
    }
  }
`;

export default WritersBooksPage;
